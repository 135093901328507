import { createSelector } from 'reselect';

export const getModule = state => state.app;

export const isStarted = state => getModule(state).started;
export const isDbReady = state => getModule(state).db.ready;
export const getSwUpdateAvailable = state => getModule(state).swUpdateAvailable;

export const getProductsData = state => getModule(state).data.products;
export const getInventoriesData = state => getModule(state).data.inventories;

export const hasProductsData = state =>
  getProductsData(state).lastUpdated !== null &&
  !getProductsData(state).fetching;
export const hasInventoriesData = state =>
  getModule(state).data.inventories.lastUpdated !== null;

export const isProductsOffline = state => getProductsData(state).offline;

export const isDataReady = createSelector(
  [hasProductsData, hasInventoriesData],
  (hasProducts, hasInventories) => {
    return hasProducts && hasInventories;
  }
);

export const isDataError = state =>
  getModule(state).data.products.error ||
  getModule(state).data.inventories.error;

export const getActiveInventory = state => getModule(state).inventory;

export const getAvailableInventories = state =>
  getModule(state).data.inventories.items;

export const isInventoriesFetching = state =>
  getModule(state).data.inventories.fetching;

export const isUploadFetching = state => getModule(state).upload.fetching;

export const getUploadError = state => getModule(state).upload.error;
export const getUploadConfirmable = state =>
  getModule(state).upload.confirmable;

export const getAlertShow = state => getModule(state).alert.show;
export const getAlertTitle = state => getModule(state).alert.title;
export const getAlertMessage = state => getModule(state).alert.message;

export default {
  getModule,
  isStarted,
  isDbReady,
  getSwUpdateAvailable,
  isProductsOffline,
  isDataReady,
  isDataError,
  getProductsData,
  getInventoriesData,
  getActiveInventory,
  getAvailableInventories,
  isInventoriesFetching,
  isUploadFetching,
  getUploadError,
  getUploadConfirmable,
  getAlertShow,
  getAlertTitle,
  getAlertMessage
};
