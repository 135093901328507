export const getState = state => state.inventory;

export const getFetching = state => getState(state).fetching;

export const getLastScannedItem = state => getState(state).lastScannedItem;

export default {
  getState,
  getFetching,
  getLastScannedItem
};
