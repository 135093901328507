export const INIT = 'app/INIT';
export const DB_UPDATE = 'app/DB_UPDATE';
export const SW_UPDATE_AVAILABLE = 'app/SW_UPDATE_AVAILABLE';
export const POUCHDB_SET_REDUCER = '@@redux-pouchdb-plus/SET_REDUCER';
export const START = 'app/START';
export const FINISH = 'app/FINISH';
export const CLEAR_PRODUCTS = 'app/CLEAR_PRODUCTS';
export const FETCH_PRODUCTS = 'app/FETCH_PRODUCTS';
export const RECEIVE_PRODUCTS = 'app/RECEIVE_PRODUCTS';
export const UPDATE_PRODUCTS_FINISHED = 'app/UPDATE_PRODUCTS_FINISHED';
export const FETCH_PRODUCTS_OFFLINE = 'app/FETCH_PRODUCTS_OFFLINE';
export const FETCH_PRODUCTS_ERROR = 'app/FETCH_PRODUCTS_ERROR';
export const FETCH_INVENTORIES = 'app/FETCH_INVENTORIES';
export const RECEIVE_INVENTORIES = 'app/RECEIVE_INVENTORIES';
export const FETCH_INVENTORIES_ERROR = 'app/FETCH_INVENTORIES_ERROR';
export const CLEAR_INVENTORIES = 'app/CLEAR_INVENTORIES';
export const FETCH_UPLOAD = 'app/FETCH_UPLOAD';
export const RECEIVE_UPLOAD = 'app/RECEIVE_UPLOAD';
export const ERROR_UPLOAD = 'app/ERROR_UPLOAD';
export const DISMISS_UPLOAD_ERROR = 'app/DISMISS_UPLOAD_ERROR';
export const DISPLAY_ALERT = 'app/DISPLAY_ALERT';
export const CONFIRM_ALERT = 'app/CONFIRM_ALERT';

export default {
  INIT,
  DB_UPDATE,
  SW_UPDATE_AVAILABLE,
  POUCHDB_SET_REDUCER,
  START,
  FINISH,
  CLEAR_PRODUCTS,
  FETCH_PRODUCTS,
  RECEIVE_PRODUCTS,
  UPDATE_PRODUCTS_FINISHED,
  FETCH_PRODUCTS_OFFLINE,
  FETCH_PRODUCTS_ERROR,
  FETCH_INVENTORIES,
  RECEIVE_INVENTORIES,
  FETCH_INVENTORIES_ERROR,
  CLEAR_INVENTORIES,
  FETCH_UPLOAD,
  RECEIVE_UPLOAD,
  ERROR_UPLOAD,
  DISMISS_UPLOAD_ERROR,
  DISPLAY_ALERT,
  CONFIRM_ALERT
};
