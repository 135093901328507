import { combineReducers } from 'redux';

import * as types from './types';

const initialState = {
  fetching: false,
  error: null,
  page: 1,
  filteredCount: 0,
  totalCount: 0,
  length: 8,
  data: null,
  sort: [
    {
      column: 0,
      dir: 'desc'
    }
  ],
  filter: {
    search: '',
    property: ''
  }
};

const fetching = (state = initialState.fetching, action) => {
  switch (action.type) {
    case types.FETCH:
      return true;

    case types.RECEIVE:
      return false;

    default:
      return state;
  }
};

const data = (state = initialState.data, action) => {
  switch (action.type) {
    case types.RECEIVE:
      return action.payload.data.data;

    default:
      return state;
  }
};

const page = (state = initialState.page, action) => {
  switch (action.type) {
    case types.CHANGE_PAGE:
      return action.payload.page;

    case types.CHANGE_FILTER:
    case types.CHANGE_SORT:
      return 1;

    default:
      return state;
  }
};

const length = (state = initialState.length, action) => {
  return state;
};

const sort = (state = initialState.sort, action) => {
  switch (action.type) {
    case types.CHANGE_SORT: {
      const current = state.find(
        entry => entry.column === action.payload.index
      );
      const direction = current && current.dir === 'asc' ? 'desc' : 'asc';

      return [
        {
          column: action.payload.index,
          dir: direction
        }
      ];
    }

    default:
      return state;
  }
};

const filter = (state = initialState.filter, action) => {
  switch (action.type) {
    case types.CHANGE_FILTER:
      return {
        search: action.payload.search,
        property: action.payload.property
      };

    default:
      return state;
  }
};

const filteredCount = (state = initialState.filteredCount, action) => {
  switch (action.type) {
    case types.RECEIVE:
      return action.payload.data.recordsFiltered;

    default:
      return state;
  }
};

const totalCount = (state = initialState.totalCount, action) => {
  switch (action.type) {
    case types.RECEIVE:
      return action.payload.data.recordsTotal;

    default:
      return state;
  }
};

const rootReducer = combineReducers({
  fetching,
  data,
  page,
  length,
  sort,
  filter,
  filteredCount,
  totalCount
});

export default rootReducer;
