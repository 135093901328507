import React from 'react';
import ReactDOM from 'react-dom';
import 'babel-polyfill';

import configureStore from './redux/configureStore';
import Root from './modules/root';
import registerServiceWorker from './registerServiceWorker';
import {
  init,
  fetchProducts,
  fetchInventories,
  swUpdateAvailable
} from './redux/modules/app/operations';
import { getProductsData } from './redux/modules/app/selectors';
import { syncResults } from './redux/modules/results/operations';
import { initializeStore } from './redux/store';

const { persistor, store } = configureStore(/* initialState */);
initializeStore(store);

if (process.env.NODE_ENV !== 'production') {
  window.store = store;
}

const onBeforeLift = () => {
  const { dispatch, getState } = store;

  const state = getState();
  const products = getProductsData(state);

  dispatch(init());

  if (
    !products.lastUpdated ||
    Date.parse(products.lastUpdated) <= Date.now() - 5 * 24 * 60 * 60 * 1000
  ) {
    dispatch(fetchProducts());
  }

  dispatch(fetchInventories());
  dispatch(syncResults());
};

ReactDOM.render(
  <Root store={store} persistor={persistor} onBeforeLift={onBeforeLift} />,
  document.getElementById('root')
);

registerServiceWorker({
  onUpdateAvailable: () => store.dispatch(swUpdateAvailable())
});

if (window.navigator.storage && window.navigator.storage.persist) {
  window.navigator.storage.persist();
}
