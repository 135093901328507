import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { get } from 'lodash';

import Footer from './footer';
import {
  TableContainer,
  TableScroller,
  LoaderContainer,
  InfoColumn,
  TableRow,
  TableCell,
  BodyTableCell,
  DefaultWrapper
} from './components';

export default class DataTable extends Component {
  static propTypes = {
    fetching: PropTypes.bool,
    columns: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        property: PropTypes.string.isRequired,
        render: PropTypes.func,
        cellRender: PropTypes.func,
        sortProperty: PropTypes.string,
        disableSort: PropTypes.bool
      })
    ),
    data: PropTypes.array,
    sort: PropTypes.array,
    page: PropTypes.number,
    length: PropTypes.number,
    lengthOptions: PropTypes.array,
    filteredCount: PropTypes.number,
    totalCount: PropTypes.number,
    onPageChange: PropTypes.func,
    onPageLengthChange: PropTypes.func,
    onSort: PropTypes.func,
    onRowClick: PropTypes.func,
    rowRender: PropTypes.func,
    emptyMessage: PropTypes.string,
    wrapperComponent: PropTypes.oneOfType([PropTypes.node, PropTypes.func])
  };

  static defaultProps = {
    data: [],
    sort: undefined
  };

  createSortHandler = index => () => {
    const { onSort } = this.props;

    onSort && onSort(index);
  };

  createRowClickHandler = row => () => {
    const { onRowClick } = this.props;

    onRowClick && onRowClick(row);
  };

  getValue(column, row) {
    const value = get(row, column.property);

    if (column.render) {
      return column.render(value, row, column);
    }

    return value;
  }

  render() {
    const {
      fetching,
      columns,
      data,
      sort,
      page,
      length,
      lengthOptions,
      filteredCount,
      totalCount,
      onPageChange,
      onPageLengthChange,
      onRowClick,
      rowRender,
      emptyMessage,
      wrapperComponent
    } = this.props;

    const emptyRows =
      length - Math.min(length, data && data.length ? data.length : 1);
    const hasFooter = !!onPageChange;

    let loader;

    if (fetching) {
      loader = (
        <LoaderContainer footerPadding={hasFooter}>
          <CircularProgress size={32} />
        </LoaderContainer>
      );
    }

    let body;

    if (data) {
      body = (
        <TableRow>
          <InfoColumn colSpan={columns.length}>
            <Typography variant="body1">
              {emptyMessage || 'Keine Einträge vorhanden.'}
            </Typography>
          </InfoColumn>
        </TableRow>
      );

      if (data.length) {
        body = data.map((row, index) => {
          const rowChildren = columns.map((column, index) => {
            if (column.cellRender) {
              return column.cellRender(
                column,
                index,
                this.getValue(column, row),
                row
              );
            }

            return (
              <BodyTableCell
                key={`${index}.${column.property}`}
                {...column.cellProps}
              >
                {this.getValue(column, row)}
              </BodyTableCell>
            );
          });

          if (rowRender) {
            return rowRender(row, {
              key: index,
              children: rowChildren,
              onClick: onRowClick ? this.createRowClickHandler(row) : null
            });
          }

          return (
            <TableRow
              key={index}
              onClick={onRowClick ? this.createRowClickHandler(row) : null}
            >
              {rowChildren}
            </TableRow>
          );
        });
      }
    } else {
      body = (
        <TableRow>
          <InfoColumn colSpan={columns.length} />
        </TableRow>
      );
    }

    let Wrapper = DefaultWrapper;
    if (wrapperComponent) {
      Wrapper = wrapperComponent;
    }

    return (
      <Wrapper>
        <TableContainer>
          <TableScroller>
            <Table>
              <TableHead>
                <TableRow>
                  {columns.map((column, index) => {
                    if (sort !== undefined && !column.disableSort) {
                      const sortEntry = sort.find(
                        entry => entry.column === index
                      );

                      return (
                        <TableCell
                          key={`${index}.${column.property}`}
                          {...column.cellProps}
                        >
                          <TableSortLabel
                            active={!!sortEntry}
                            direction={sortEntry && sortEntry.dir}
                            onClick={this.createSortHandler(index)}
                          >
                            {column.label}
                          </TableSortLabel>
                        </TableCell>
                      );
                    }

                    return (
                      <TableCell
                        key={`${index}.${column.property}`}
                        {...column.cellProps}
                      >
                        {column.label}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {body}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 48 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
              {hasFooter ? (
                <Footer
                  data={data}
                  page={page}
                  length={length}
                  lengthOptions={lengthOptions}
                  onPageChange={onPageChange}
                  onPageLengthChange={onPageLengthChange}
                  filteredCount={filteredCount}
                  totalCount={totalCount}
                />
              ) : null}
            </Table>
          </TableScroller>
          {loader}
        </TableContainer>
      </Wrapper>
    );
  }
}
