import Dexie from 'dexie';

import { getProducts, getInventories, uploadFinalData } from 'app/api';
import productsRepository from 'app/db/model/products';
import { default as inventoryActions } from 'redux/modules/inventory/actions';
import { operations as resultsOperations } from 'redux/modules/results';
import {
  MSG_ERROR_CONNECTION,
  MSG_ERROR_INTERNAL,
  MSG_ERROR_CONFLICT,
  MSG_ERROR_GONE
} from 'config/constants';
import scannedItemsRepository from 'app/db/model/scanned-items';
import scannedProductsRepository from 'app/db/model/scanned-products';
import { syncResults } from 'redux/modules/results/operations';
import { default as actions } from './actions';
import { default as selectors } from './selectors';

export const init = actions.init;

export const dbUpdate = actions.dbUpdate;

export const swUpdateAvailable = actions.swUpdateAvailable;

export const start = inventory => dispatch => {
  dispatch(actions.start(inventory));
  dispatch(syncResults());
};

export const finish = () => async (dispatch, getState) => {
  const state = getState();
  const activeInventory = selectors.getActiveInventory(state);

  dispatch(actions.fetchUpload());

  const inventoryData = await Promise.all([
    scannedItemsRepository.builder().toArray(),
    scannedProductsRepository.builder().toArray()
  ]).then(values => {
    const items = values[0];
    const rawProducts = values[1];

    const products = {};

    for (const product of rawProducts) {
      products[product.id] = product.amount;
    }

    return {
      items: items,
      products: products
    };
  });

  if (!inventoryData.items.length) {
    dispatch(actions.receiveUpload());
    dispatch(
      displayAlert(
        'Inventur erfolgreich!',
        'Die Inventurdaten wurden übertragen. Vielen Dank!'
      )
    );

    dispatch(cleanup());
    return;
  }

  return uploadFinalData(activeInventory, inventoryData)
    .then(async response => {
      switch (response.status) {
        case 200:
          const data = await response.json();

          await dispatch(resultsOperations.addResult(data.result));

          dispatch(actions.receiveUpload());
          dispatch(
            displayAlert(
              'Inventur erfolgreich!',
              'Die Inventurdaten wurden übertragen. Vielen Dank!'
            )
          );

          dispatch(cleanup());
          break;

        case 409:
          dispatch(errorUpload(MSG_ERROR_CONFLICT, true));
          break;

        case 410:
          dispatch(errorUpload(MSG_ERROR_GONE, true));
          break;

        default:
          dispatch(errorUpload(MSG_ERROR_INTERNAL));
      }
    })
    .catch(error => {
      console.error(error);
      dispatch(errorUpload(MSG_ERROR_CONNECTION));
    });
};

export const errorUpload = (message, confirmable) => dispatch => {
  dispatch(actions.errorUpload(message, confirmable));
};

export const cleanup = () => async dispatch => {
  await Promise.all([
    scannedItemsRepository.clear(),
    scannedProductsRepository.clear()
  ]).then(() => {
    dispatch(inventoryActions.clearItems());
  });

  dispatch(actions.finish());
  dispatch(actions.clearInventories());
  dispatch(fetchInventories());
};

export const clearProducts = () => async dispatch => {
  await productsRepository.clear();

  dispatch(actions.clearProducts());
};

export const fetchProducts = () => (dispatch, getState) => {
  dispatch(actions.fetchProducts());

  const state = getState();
  const { lastCursor } = selectors.getProductsData(state);

  if (!lastCursor) {
    dispatch(clearProducts());
  }

  let retryCount = 0;

  const fetcher = async () => {
    try {
      const state = getState();

      let cursor = selectors.getProductsData(state).cursor;

      while (true) {
        const result = await getProducts(cursor).then(response => {
          if (response.ok) {
            return response.json();
          }

          dispatch(
            actions.fetchProductsError(
              `Fehler beim Laden der Stammdaten. Server Fehler ${response.status}! Bitte später nochmal versuchen.`
            )
          );
        });

        if (result === undefined) {
          return;
        }

        try {
          await productsRepository.insertBulk(result.products);
        } catch (error) {
          if (error instanceof Dexie.BulkError) {
            let quotaError = false;

            error.failures.forEach(failure => {
              if (failure instanceof Dexie.QuotaExceededError) {
                quotaError = true;
              }
            });

            if (quotaError) {
              dispatch(
                actions.fetchProductsError(
                  'Fehler beim Laden der Stammdaten. Die Anwendung verfügt nicht über genügend Speicherplatz.'
                )
              );

              return;
            }
          }

          dispatch(
            actions.fetchProductsError(
              'Datenbank Fehler beim Laden der Stammdaten.'
            )
          );

          return;
        }

        if (!result.nextCursor) {
          dispatch(actions.updateProductsFinished());
          return;
        }

        cursor = result.nextCursor;

        dispatch(actions.receiveProducts(cursor, result.lastCursor));

        retryCount = 0;
      }
    } catch (error) {
      dispatch(actions.fetchProductsOffline());

      retryCount++;

      if (retryCount > 8) {
        dispatch(
          actions.fetchProductsError(
            'Fehler beim Laden der Stammdaten. Bitte Internetverbindung prüfen und nur in der Filiale verbinden.'
          )
        );

        return;
      }

      setTimeout(() => {
        fetcher();
      }, retryCount * retryCount * 1000);
    }
  };

  fetcher();
};

export const fetchInventories = () => dispatch => {
  dispatch(actions.fetchInventories());

  getInventories()
    .then(data => {
      dispatch(actions.receiveInventories(data.inventories));
    })
    .catch(error => {
      console.error(error);
      dispatch(actions.fetchInventoriesError());
    });
};

export const forceDeleteData = () => dispatch => {
  dispatch(dismissUploadError());
  dispatch(cleanup());
};

export const dismissUploadError = actions.dismissUploadError;

export const displayAlert = actions.displayAlert;

export const confirmAlert = actions.confirmAlert;

export default {
  init,
  dbUpdate,
  swUpdateAvailable,
  start,
  finish,
  errorUpload,
  cleanup,
  fetchProducts,
  fetchInventories,
  forceDeleteData,
  dismissUploadError,
  displayAlert,
  confirmAlert
};
