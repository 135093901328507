import moment from 'moment';

export const config = {
  columns: [
    {
      label: 'Letzter Scan',
      property: 'scan_date',
      render: (value, row) =>
        value && row.scan_date
          ? moment(value).format('DD.MM.YYYY HH:mm:ss')
          : value
    },
    {
      label: 'Parma Nummer',
      property: 'parma_number'
    },
    {
      label: 'EAN',
      property: 'ean'
    },
    {
      label: 'Beschreibung',
      property: 'full_description'
    },
    {
      label: 'Anzahl',
      property: 'amount'
    }
  ]
};
