import { connect } from 'react-redux';

import Alert from 'modules/util/alert';
import {
  operations as appOperations,
  selectors as appSelectors
} from 'redux/modules/app';

const mapStateToProps = state => {
  return {
    open: appSelectors.getAlertShow(state),
    title: appSelectors.getAlertTitle(state),
    message: appSelectors.getAlertMessage(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onClose: () => {
      dispatch(appOperations.confirmAlert());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Alert);
