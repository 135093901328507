import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';

import { selectors } from 'redux/modules/app';

export class UpdateAvailable extends Component {
  static propTypes = {
    swUpdateAvailable: PropTypes.bool
  };

  state = {
    dismissed: false
  };

  handleUpdate = () => {
    window.location.reload();
  };

  handleClose = () => {
    this.setState({ dismissed: true });
  };

  render() {
    const { swUpdateAvailable } = this.props;
    const { dismissed } = this.state;

    return (
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={swUpdateAvailable && !dismissed}
        message={
          <span>Eine neue App Version ist verfügbar. Bitte aktualisieren!</span>
        }
        action={
          <Button
            dense
            variant="contained"
            color="primary"
            onClick={this.handleUpdate}
          >
            Jetzt aktualisieren
          </Button>
        }
      />
    );
  }
}

const mapState = state => ({
  swUpdateAvailable: selectors.getSwUpdateAvailable(state)
});

export default connect(mapState)(UpdateAvailable);
