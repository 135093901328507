import React, { Component } from 'react';
import { Card, CardContent, Typography } from '@material-ui/core';

export default class Item extends Component {
  render() {
    const {
      item: {
        product: { ean, parma_number, full_description },
        total
      }
    } = this.props;

    return (
      <Card elevation={3} square>
        <CardContent>
          <Typography variant="subtitle2">{full_description}</Typography>
          <Typography variant="body2">EAN: {ean}</Typography>
          <Typography variant="body2">Parma: {parma_number}</Typography>
          <Typography variant="subtitle2">Gesamtanzahl: {total}</Typography>
        </CardContent>
      </Card>
    );
  }
}
