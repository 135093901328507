import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { operations, selectors } from 'redux/modules/products';
import DataTable from 'modules/util/datatable/paginated-datatable';
import Filter from './filter';
import { config } from './config';

export class ProductsTable extends Component {
  static propTypes = {
    fetching: PropTypes.bool,
    page: PropTypes.number.isRequired,
    length: PropTypes.number.isRequired,
    filteredCount: PropTypes.number.isRequired,
    totalCount: PropTypes.number.isRequired,
    data: PropTypes.array,
    fetchData: PropTypes.func.isRequired,
    changePage: PropTypes.func.isRequired,
    changeSort: PropTypes.func.isRequired
  };

  componentDidMount() {
    this.props.fetchData();
  }

  render() {
    const {
      fetching,
      data,
      page,
      length,
      sort,
      filteredCount,
      totalCount,
      changePage,
      changeSort
    } = this.props;

    return (
      <div>
        <Filter />
        <DataTable
          fetching={fetching}
          data={data}
          page={page}
          length={length}
          sort={sort}
          filteredCount={filteredCount}
          totalCount={totalCount}
          onPageChange={changePage}
          onSort={changeSort}
          {...config}
        />
      </div>
    );
  }
}

const mapState = state => ({
  fetching: selectors.getFetching(state),
  page: selectors.getPage(state),
  length: selectors.getLength(state),
  filteredCount: selectors.getFilteredCount(state),
  totalCount: selectors.getTotalCount(state),
  data: selectors.getData(state),
  sort: selectors.getSort(state)
});

const mapDispatch = dispatch => ({
  fetchData: () => dispatch(operations.fetch()),
  changePage: page => dispatch(operations.changePage(page)),
  changeSort: index => dispatch(operations.changeSort(index))
});

export default connect(mapState, mapDispatch)(ProductsTable);
