import React, { Component } from 'react';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';

import ResultsTable from './table';

const Container = styled.div`
  padding: 8px;
`;

const Title = styled(Typography)`
  margin-top: 10px !important;
  margin-bottom: 25px !important;
`;

export default class ResultsOverview extends Component {
  static propTypes = {};

  render() {
    return (
      <Container>
        <Title variant="h6">Übertragene Daten</Title>
        <ResultsTable />
      </Container>
    );
  }
}
