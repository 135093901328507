import { createMuiTheme } from '@material-ui/core/styles';
import { purple, green, red } from '@material-ui/core/colors';

const theme = createMuiTheme({
  palette: {
    common: {
      darkBlack: 'rgba(0, 0, 0, 0.87)',
      darkWhite: 'rgba(255, 255, 255, 0.87)',
      faintBlack: 'rgba(0, 0, 0, 0.12)',
      fullBlack: 'rgba(0, 0, 0, 1)',
      fullWhite: 'rgba(255, 255, 255, 1)',
      lightBlack: 'rgba(0, 0, 0, 0.54)',
      lightWhite: 'rgba(255, 255, 255, 0.54)',
      minBlack: 'rgba(0, 0, 0, 0.26)'
    },
    primary: {
      ...purple,
      500: '#93419c'
    },
    success: green,
    error: red
  }
});

if (process.env.NODE_ENV !== 'production') {
  console.log(theme);
}

export default theme;
