import db from '../db';

class Results {
  builder() {
    return db.results;
  }

  insertBulk(items) {
    return this.builder().bulkPut(items);
  }

  insert(item) {
    return this.builder().put(item);
  }

  remove(id) {
    return this.builder().delete(id);
  }

  clear() {
    return this.builder().clear();
  }
}

const Repository = new Results();

export default Repository;
