import db from '../db';

class ScannedProducts {
  builder() {
    return db.scanned_products;
  }

  insert(item) {
    return this.builder().put(item);
  }

  remove(id) {
    return this.builder().delete(id);
  }

  clear() {
    return this.builder().clear();
  }

  find(id) {
    return this.builder()
      .where('id')
      .equals(id)
      .first();
  }
}

const scannedProductsRepository = new ScannedProducts();

export default scannedProductsRepository;
