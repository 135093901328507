import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { operations, selectors } from 'redux/modules/inventory';
import Item from 'modules/item';

import styles from './styles.module.css';

export class Scanner extends Component {
  static propTypes = {
    fetching: PropTypes.bool,
    lastItem: PropTypes.object,
    onScan: PropTypes.func.isRequired
  };

  state = {
    code: ''
  };

  constructor(props) {
    super(props);

    this.handleKeydown = this.handleKeydown.bind(this);
  }

  handleKeydown = e => {
    const { onScan } = this.props;
    const { code } = this.state;

    if ((e.keyCode < 48 || e.keyCode > 57) && e.keyCode !== 13) {
      return;
    }

    if (e.keyCode === 13) {
      if (!code) {
        return;
      }

      onScan(code);

      this.setState({ code: '' });

      return;
    }

    // we cant use e.key on old devices, so get number by key code
    const number = e.keyCode - 48;

    this.setState({ code: code + number });
  };

  componentDidMount = () => {
    document.addEventListener('keydown', this.handleKeydown);
  };

  componentWillUnmount = () => {
    document.removeEventListener('keydown', this.handleKeydown);
  };

  render() {
    const { lastItem } = this.props;

    let lastItemElement = null;
    if (lastItem) {
      lastItemElement = (
        <div className={styles.lastItem}>
          <h3>Letzter Scan:</h3>
          <Item item={lastItem} />
        </div>
      );
    }

    return (
      <div className={styles.scanner}>
        <h1 className={styles.scanInfo}>Bitte scannen...</h1>

        {lastItemElement}
      </div>
    );
  }
}

const mapState = state => ({
  fetching: selectors.getFetching(state),
  lastItem: selectors.getLastScannedItem(state)
});

const mapDispatch = dispatch => ({
  onScan: code => {
    dispatch(operations.scanItem(code));
  }
});

export default connect(mapState, mapDispatch)(Scanner);
