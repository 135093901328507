import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, FormGroup, FormControlLabel } from '@material-ui/core';

import styles from './styles.module.css';

export default class ReductionOptions extends Component {
  static propTypes = {
    reduction: PropTypes.shape({
      old: PropTypes.bool.isRequired,
      damaged: PropTypes.bool.isRequired,
      fashion: PropTypes.bool.isRequired,
      unsaleable: PropTypes.bool.isRequired
    }).isRequired,
    onChange: PropTypes.func.isRequired
  };

  handleChange = (event, checked, property) => {
    const { reduction, onChange } = this.props;

    const newReduction = {
      ...reduction,
      [property]: checked
    };

    const checkedCount = Object.entries(newReduction).reduce(
      (acc, [prop, checked]) => {
        return acc + checked;
      },
      0
    );

    if (checkedCount > 1) {
      newReduction.unsaleable = true;
    }

    onChange(newReduction);
  };

  render() {
    const {
      reduction: { old, damaged, fashion, unsaleable }
    } = this.props;

    return (
      <div className={styles.root}>
        <FormGroup row>
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={old}
                onChange={(event, checked) =>
                  this.handleChange(event, checked, 'old')
                }
                value="old"
              />
            }
            label="Alt"
          />
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={damaged}
                onChange={(event, checked) =>
                  this.handleChange(event, checked, 'damaged')
                }
                value="damaged"
              />
            }
            label="Beschädigt"
          />
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={fashion}
                onChange={(event, checked) =>
                  this.handleChange(event, checked, 'fashion')
                }
                value="fashion"
              />
            }
            label="Modisch"
          />
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={unsaleable}
                onChange={(event, checked) =>
                  this.handleChange(event, checked, 'unsaleable')
                }
                value="unsaleable"
              />
            }
            label="Unverkäuflich"
          />
        </FormGroup>
      </div>
    );
  }
}
