import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/es/storage';

import * as types from './types';

const initialState = {
  started: false,
  swUpdateAvailable: false,
  inventory: null,
  data: {
    products: {
      fetching: false,
      offline: false,
      error: null,
      cursor: null,
      lastCursor: null,
      lastUpdated: null
    },
    inventories: {
      fetching: false,
      error: false,
      lastUpdated: null,
      items: []
    }
  },
  upload: {
    fetching: false,
    error: null,
    confirmable: false
  },
  alert: {
    show: false,
    title: null,
    message: null
  }
};

const swUpdateAvailable = (state = false, action) => {
  switch (action.type) {
    case types.SW_UPDATE_AVAILABLE:
      return true;

    default:
      return state;
  }
};

const products = (state, action) => {
  switch (action.type) {
    case types.FETCH_PRODUCTS: {
      return {
        ...state,
        fetching: true
      };
    }

    case types.FETCH_PRODUCTS_OFFLINE: {
      return {
        ...state,
        offline: true
      };
    }

    case types.FETCH_PRODUCTS_ERROR: {
      return {
        ...state,
        fetching: false,
        error: {
          message: action.payload.message
        }
      };
    }

    case types.RECEIVE_PRODUCTS: {
      return {
        ...state,
        offline: false,
        error: null,
        cursor: action.payload.cursor,
        lastCursor: action.payload.lastCursor || state.lastCursor
      };
    }

    case types.UPDATE_PRODUCTS_FINISHED: {
      return {
        ...state,
        fetching: false,
        offline: false,
        error: null,
        cursor: null,
        lastCursor: null,
        lastUpdated: new Date().toISOString()
      };
    }

    case types.CLEAR_PRODUCTS: {
      return {
        ...state,
        lastUpdated: null
      };
    }

    case types.INIT: {
      return {
        ...state,
        fetching: false,
        offline: false,
        error: null
      };
    }

    default:
      return state;
  }
};

const productsReducer = persistReducer(
  {
    key: 'app.data.products',
    storage,
    whitelist: ['cursor', 'lastCursor', 'lastUpdated']
  },
  products
);

const inventories = (state, action) => {
  switch (action.type) {
    case types.FETCH_INVENTORIES: {
      return {
        ...state,
        fetching: true,
        error: false
      };
    }

    case types.FETCH_INVENTORIES_ERROR: {
      return {
        ...state,
        fetching: false,
        error: true
      };
    }

    case types.RECEIVE_INVENTORIES: {
      return {
        ...state,
        fetching: false,
        lastUpdated: new Date().toISOString(),
        items: action.payload.inventories
      };
    }

    case types.CLEAR_INVENTORIES: {
      return {
        ...state,
        lastUpdated: null,
        items: []
      };
    }

    case types.INIT: {
      return {
        ...state,
        fetching: false,
        error: false
      };
    }

    default:
      return state;
  }
};

const inventoriesReducer = persistReducer(
  { key: 'app.data.inventories', storage, whitelist: ['lastUpdated', 'items'] },
  inventories
);

const dataReducer = (state, action) => ({
  ...state,
  products: productsReducer(state.products, action),
  inventories: inventoriesReducer(state.inventories, action)
});

const uploadReducer = (state, action) => {
  switch (action.type) {
    case types.INIT: {
      return initialState.upload;
    }

    case types.FETCH_UPLOAD: {
      return {
        ...state,
        fetching: true,
        error: null,
        confirmable: false
      };
    }

    case types.RECEIVE_UPLOAD: {
      return {
        ...state,
        fetching: false,
        error: null
      };
    }

    case types.ERROR_UPLOAD: {
      return {
        ...state,
        fetching: false,
        error: action.payload.message,
        confirmable: action.payload.confirmable
      };
    }

    case types.DISMISS_UPLOAD_ERROR: {
      return {
        ...state,
        error: null
      };
    }

    default:
      return state;
  }
};

const alertReducer = (state, action) => {
  switch (action.type) {
    case types.DISPLAY_ALERT: {
      return {
        ...state,
        show: true,
        title: action.payload.title,
        message: action.payload.message
      };
    }

    case types.CONFIRM_ALERT: {
      return {
        ...state,
        show: false
      };
    }

    default:
      return state;
  }
};

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.START: {
      return {
        ...state,
        started: true,
        inventory: action.payload.inventory
      };
    }

    case types.FINISH: {
      return {
        ...state,
        started: false,
        inventory: null
      };
    }

    default:
      return {
        ...state,
        swUpdateAvailable: swUpdateAvailable(state.swUpdateAvailable, action),
        data: dataReducer(state.data, action),
        upload: uploadReducer(state.upload, action),
        alert: alertReducer(state.alert, action)
      };
  }
};

export default persistReducer(
  { key: 'app', storage, whitelist: ['started', 'inventory'] },
  appReducer
);
