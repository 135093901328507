import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import TablePagination from '@material-ui/core/TablePagination';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';

import { TableFooter } from './components';

const actionsStyles = theme => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(2.5)
  }
});

class TablePaginationActions extends Component {
  handleFirstPageButtonClick = event => {
    this.props.onChangePage(event, 0);
  };

  handleBackButtonClick = event => {
    this.props.onChangePage(event, this.props.page - 1);
  };

  handleNextButtonClick = event => {
    this.props.onChangePage(event, this.props.page + 1);
  };

  handleLastPageButtonClick = event => {
    this.props.onChangePage(
      event,
      Math.max(0, Math.ceil(this.props.count / this.props.rowsPerPage) - 1)
    );
  };

  render() {
    const { classes, count, page, rowsPerPage } = this.props;

    return (
      <div className={classes.root}>
        <IconButton
          onClick={this.handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="Erste Seite"
        >
          <FirstPageIcon />
        </IconButton>
        <IconButton
          onClick={this.handleBackButtonClick}
          disabled={page === 0}
          aria-label="Vorherige Seite"
        >
          <KeyboardArrowLeft />
        </IconButton>
        <IconButton
          onClick={this.handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Nächste Seite"
        >
          <KeyboardArrowRight />
        </IconButton>
        <IconButton
          onClick={this.handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Letzte Seite"
        >
          <LastPageIcon />
        </IconButton>
      </div>
    );
  }
}

TablePaginationActions.propTypes = {
  classes: PropTypes.object.isRequired,
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired
};

const TablePaginationActionsWrapped = withStyles(actionsStyles)(
  TablePaginationActions
);

export default class Footer extends Component {
  static propTypes = {
    data: PropTypes.array,
    page: PropTypes.number,
    length: PropTypes.number,
    lengthOptions: PropTypes.array,
    onPageChange: PropTypes.func,
    onPageLengthChange: PropTypes.func,
    filteredCount: PropTypes.number,
    totalCount: PropTypes.number
  };

  handleChangePage = (event, page) => {
    const { onPageChange } = this.props;

    onPageChange && onPageChange(page);
  };

  handleChangeRowsPerPage = event => {
    const { onPageLengthChange } = this.props;

    onPageLengthChange && onPageLengthChange(event.target.value);
  };

  render() {
    const {
      page,
      length,
      filteredCount,
      totalCount,
      lengthOptions
    } = this.props;

    return (
      <TableFooter>
        <TableRow>
          <TablePagination
            colSpan={999}
            count={filteredCount || totalCount}
            rowsPerPage={length}
            rowsPerPageOptions={lengthOptions || [length]}
            page={page}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActionsWrapped}
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} von ${count}`
            }
            labelRowsPerPage="Pro Seite"
          />
        </TableRow>
      </TableFooter>
    );
  }
}
