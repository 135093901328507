import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { CircularProgress, LinearProgress } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import ErrorIcon from '@material-ui/icons/Error';
import OfflineIcon from '@material-ui/icons/CloudOff';

import { selectors as appSelectors } from 'redux/modules/app';

import styles from './styles.module.css';

const ProgressInfo = styled.div`
  display: inline-block;
  min-width: 50vw;
  max-width: 80vw;
  margin-bottom: 24px;
`;

const ErrorMessage = styled.div``;

export class DataLoader extends Component {
  render() {
    const { products, offline, error } = this.props;

    if (error) {
      return (
        <div className={styles.root}>
          <div className={styles.container}>
            <ErrorIcon />
            <Typography align="center" variant="subtitle2">
              {error.message}
            </Typography>
          </div>
        </div>
      );
    }

    let offlineMsg = null;

    if (offline) {
      offlineMsg = (
        <ErrorMessage>
          <OfflineIcon />
          <Typography align="center" variant="subtitle2">
            Internetverbindung unterbrochen. Versuche neu zu verbinden...
          </Typography>
          <CircularProgress size={24} />
        </ErrorMessage>
      );
    }

    let progress = null;

    if (products.lastCursor) {
      progress = ((products.cursor || 0) / (products.lastCursor || 1)) * 100;
    }

    return (
      <div className={styles.root}>
        <div className={styles.container}>
          <ProgressInfo>
            <Typography align="center" variant="subtitle2">
              Aktualisiere Stammdaten...
            </Typography>
            {progress === null ? (
              <LinearProgress />
            ) : (
              <LinearProgress
                mode={
                  products.lastCursor === null ? 'indeterminate' : 'determinate'
                }
                value={progress}
              />
            )}
            {progress !== null ? (
              <Typography align="right" variant="subtitle2">
                {parseInt(progress, 0)} %
              </Typography>
            ) : null}
          </ProgressInfo>
          {offlineMsg}
        </div>
      </div>
    );
  }
}

const mapState = state => ({
  products: appSelectors.getProductsData(state),
  offline: appSelectors.isProductsOffline(state),
  error: appSelectors.isDataError(state)
});

export default connect(mapState)(DataLoader);
