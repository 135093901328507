import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

import styles from './styles.module.css';

export default class AmountSelection extends Component {
  static propTypes = {
    amount: PropTypes.number,
    onChange: PropTypes.func.isRequired
  };

  handleChange = e => {
    const { onChange } = this.props;
    const value = parseInt(e.target.value, 10);

    onChange(value);
  };

  adjustAmount = value => {
    const { amount, onChange } = this.props;

    const newValue = +(amount || 0) + value;

    if (newValue < 0) {
      return;
    }

    onChange(newValue);
  };

  render() {
    const { amount } = this.props;

    return (
      <div className={styles.container}>
        <Grid container>
          <Grid item xs={4} className={styles.btnContainer}>
            <Button variant="contained" onClick={() => this.adjustAmount(-1)}>
              <RemoveIcon />
            </Button>
          </Grid>
          <Grid item xs={4} className={styles.btnContainer}>
            <Input
              type="number"
              value={amount || ''}
              onChange={this.handleChange}
              className={styles.input}
            />
          </Grid>
          <Grid item xs={4} className={styles.btnContainer}>
            <Button variant="contained" onClick={() => this.adjustAmount(1)}>
              <AddIcon />
            </Button>
          </Grid>
        </Grid>
      </div>
    );
  }
}
