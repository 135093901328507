import uuid from 'uuid';

import actions from './actions';
import { ITEM_SCAN, ITEM_MANUAL } from './constants';
import {
  selectors as appSelectors,
  operations as appOperations
} from 'redux/modules/app';
import { playBeepSound } from 'app/audio';
import productsRepository from 'app/db/model/products';
import scannedItemsRepository from 'app/db/model/scanned-items';
import scannedProductsRepository from 'app/db/model/scanned-products';
import { operations as itemsOperations } from 'redux/modules/manual-count/items';
import LiveDataSyncService from 'app/liveDataSync';

export const addItem = item => (dispatch, getState) => {
  const state = getState();
  const inventory = appSelectors.getActiveInventory(state);

  const extendedItem = {
    id: uuid.v4(),
    date: new Date().toISOString(),
    inventory_id: inventory.id,
    deleted: false,
    synced: 0,
    ...item
  };

  return updateDatabase(extendedItem).then(entry => {
    if (entry) {
      dispatch(actions.addItem(extendedItem, entry.amount));
    } else {
      dispatch(actions.addItem(extendedItem));
    }

    LiveDataSyncService.syncItem(extendedItem);
  });
};

const updateDatabase = item => {
  return scannedItemsRepository.insert(item).then(async () => {
    const { product } = item;

    if (!product) {
      return null;
    }

    const entry = {
      id: product.id,
      parma_number: product.parma_number,
      ean: product.ean,
      full_description: product.full_description,
      scan_date: new Date().toISOString()
    };

    // get total product count
    entry.amount = await scannedItemsRepository
      .builder()
      .where('product.id')
      .equals(product.id)
      .filter(item => !item.deleted)
      .toArray()
      .then(result => result.reduce((acc, item) => acc + item.amount, 0));

    await scannedProductsRepository.insert(entry);

    return entry;
  });
};

export const scanItem = code => async dispatch => {
  dispatch(actions.addingItem(code));

  const item = {
    type: ITEM_SCAN,
    code: code,
    amount: 1
  };

  let product = await productsRepository.findByEan(code);

  if (!product) {
    const parma_code = code.substr(1, 8);

    product = await productsRepository.findByParma(parma_code);
  }

  if (!product) {
    playBeepSound();

    dispatch(
      appOperations.displayAlert(
        'Fehler!',
        'Bitte scannen Sie den Artikel erneut. Ist ein anderer Strichcode auf dem Artikel angebracht, scannen Sie bitte diesen.'
      )
    );

    return;
  }

  item.product = product;

  return dispatch(addItem(item));
};

export const addManualItem = item => dispatch => {
  return dispatch(
    addItem({
      ...item,
      type: ITEM_MANUAL
    })
  );
};

export const removeItem = item => dispatch => {
  item.deleted = true;

  return updateDatabase(item).then(() => {
    dispatch(actions.removeItem(item));
    dispatch(itemsOperations.fetch());
    LiveDataSyncService.syncItem(item);
  });
};

export default {
  scanItem,
  addManualItem,
  removeItem
};
