import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Button,
  Input,
  Select,
  TextField,
  Typography
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';

const Container = styled.div`
  display: flex;
  padding: 0 0 25px;
`;

const FilterInputContainer = styled.div`
  display: flex;
  flex: 1;
  margin-top: -16px;
`;

const FilterInput = styled(TextField)`
  flex: 1;
`;

const GapLabel = styled(Typography)`
  line-height: 32px !important;
  padding: 0 20px;
`;

const FilterSelect = styled(Select)`
  width: 120px;
  height: 32px;
`;

const RemoveFilterButton = styled(Button)`
  min-height: 33px !important;
  padding-top: 9px !important;
  padding-bottom: 9px !important;
  margin-left: 16px !important;
  margin-top: -4px !important;
`;

const RemoveFilterIcon = styled(ClearIcon)`
  margin: -5px 0 -5px -5px;
  color: ${props => props.theme.palette.primary.main};
`;

export default class Filter extends Component {
  static propTypes = {
    search: PropTypes.string,
    property: PropTypes.string,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired
      })
    ).isRequired,
    onChange: PropTypes.func
  };

  static defaultProps = {
    search: '',
    property: ''
  };

  handleFilterChange = event => {
    const { onChange, property } = this.props;

    onChange && onChange(event.target.value, property);
  };

  handlePropertyChange = event => {
    const { onChange, search } = this.props;

    onChange && onChange(search, event.target.value);
  };

  handleClear = () => {
    const { onChange, search, property } = this.props;

    if (search || property) {
      onChange && onChange('', '');
    }
  };

  render() {
    const { search, property, options } = this.props;

    return (
      <Container>
        <FilterInputContainer>
          <FilterInput
            label="Filtern nach"
            value={search}
            onChange={this.handleFilterChange}
          />
        </FilterInputContainer>
        <GapLabel>in</GapLabel>
        <FilterSelect
          native
          value={property}
          onChange={this.handlePropertyChange}
          input={<Input />}
        >
          {options.map(option => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </FilterSelect>
        <RemoveFilterButton onClick={this.handleClear}>
          <RemoveFilterIcon>
            <ClearIcon />
          </RemoveFilterIcon>
          Filter aufheben
        </RemoveFilterButton>
      </Container>
    );
  }
}
