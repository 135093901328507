import beepSound from '../media/sound/beep.mp3';

const playSound = file => {
  const audio = new Audio(file);
  audio.play();
};

export const playBeepSound = () => {
  playSound(beepSound);
};
