import actions from './actions';
import selectors from './selectors';
import scannedItemsRepository from 'app/db/model/scanned-items';
import { ITEM_MANUAL } from 'redux/modules/inventory/constants';
import { config as tableConfig } from '../../../../modules/manual-count/items-table/config';

export const fetch = () => async (dispatch, getState) => {
  const { columns } = tableConfig;
  const state = getState();

  dispatch(actions.fetch());

  const filter = selectors.getFilter(state);
  const page = selectors.getPage(state);
  const length = selectors.getLength(state);

  const totalCount = await scannedItemsRepository
    .builder()
    .where('type')
    .equals(ITEM_MANUAL)
    .filter(item => !item.deleted)
    .count();

  const builder = scannedItemsRepository
    .builder()
    .where('type')
    .equals(ITEM_MANUAL);

  builder.filter(item => !item.deleted);

  if (filter.search) {
    builder.filter(item => {
      if (!filter.property) {
        let match = false;

        for (const column of columns) {
          if (String(item[column.property]).search(filter.search) > -1) {
            match = true;
            break;
          }
        }

        return match;
      }

      return String(item[filter.property]).search(filter.search) > -1;
    });
  }

  const rows = await builder.reverse().sortBy('date');

  const filteredCount = rows.length;

  const data = {
    recordsTotal: totalCount,
    recordsFiltered: filteredCount,
    data: rows.slice(page * length, (page + 1) * length)
  };

  dispatch(actions.receive(data));
};

export const changePage = page => dispatch => {
  dispatch(actions.changePage(page));
  dispatch(fetch());
};

export const changeSort = index => dispatch => {
  dispatch(actions.changeSort(index));
  dispatch(fetch());
};

export const changeFilter = (search, property) => dispatch => {
  dispatch(actions.changeFilter(search, property));
  dispatch(fetch());
};

export default {
  fetch,
  changePage,
  changeSort,
  changeFilter
};
