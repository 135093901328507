import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import ScanIcon from '@material-ui/icons/AspectRatio';
import ManualIcon from '@material-ui/icons/PlaylistAdd';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress
} from '@material-ui/core';

import {
  operations as appOperations,
  selectors as appSelectors
} from 'redux/modules/app';
import Layout from './layout';
import Scanner from 'modules/scanner';
import ManualCountGeneral from 'modules/manual-count/general';
import ProductsOverview from 'modules/products-overview';
import ResultsOverview from 'modules/results-overview';
import Alert from 'modules/util/alert';

import styles from './styles.module.css';

const bottomTabs = [
  {
    label: 'WWS/Computer-Inventur',
    icon: <ScanIcon />,
    route: '/'
  },
  {
    label: 'Zähl/Schreibinventur',
    icon: <ManualIcon />,
    route: '/custom'
  }
];

export class WorkArea extends Component {
  static propTypes = {
    fetching: PropTypes.bool.isRequired,
    error: PropTypes.string,
    confirmable: PropTypes.bool,
    onFinish: PropTypes.func.isRequired,
    onDismissUploadError: PropTypes.func.isRequired,
    onForceDeleteData: PropTypes.func
  };

  state = {
    confirmFinish: false
  };

  componentDidUpdate(prevProps) {
    if (!prevProps.error && this.props.error) {
      this.setState({ confirmFinish: false });
    }
  }

  handleFinish = () => {
    this.props.onFinish();
  };

  handleClose = () => {
    if (this.props.fetching) {
      return;
    }

    this.setState({ confirmFinish: false });
  };

  confirmFinish = () => {
    this.setState({ confirmFinish: true });
  };

  render() {
    const { confirmFinish } = this.state;
    const {
      fetching,
      error,
      confirmable,
      onDismissUploadError,
      onForceDeleteData
    } = this.props;

    let progress = null;
    if (fetching) {
      progress = <CircularProgress size={32} />;
    }

    return (
      <div>
        <Layout bottomTabs={bottomTabs} onFinish={this.confirmFinish}>
          <Switch>
            <Route path="/" exact component={Scanner} />
            <Route path="/custom" component={ManualCountGeneral} />
            <Route path="/overview" component={ProductsOverview} />
            <Route path="/results" component={ResultsOverview} />
          </Switch>
        </Layout>
        <Dialog open={confirmFinish} onClose={this.handleClose}>
          <DialogTitle>Inventur beenden?</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Die Inventur wird final abgeschlossen und kann nicht mehr
              fortgesetzt oder verändert werden. Daten werden an die Zentrale
              übermittelt.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleClose}
              color="primary"
              disabled={fetching}
            >
              Abbrechen
            </Button>
            <Button
              onClick={this.handleFinish}
              color="primary"
              disabled={fetching}
            >
              Senden
            </Button>
          </DialogActions>
          <div className={styles.progress}>{progress}</div>
        </Dialog>
        {error && (
          <Alert
            open={true}
            title="Fehler!"
            message={error}
            onClose={onDismissUploadError}
            actions={
              confirmable
                ? [
                    {
                      label: 'Inventurdaten löschen',
                      onClick: onForceDeleteData
                    },
                    { label: 'Abbrechen', onClick: onDismissUploadError }
                  ]
                : null
            }
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    fetching: appSelectors.isUploadFetching(state),
    error: appSelectors.getUploadError(state),
    confirmable: appSelectors.getUploadConfirmable(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFinish: () => dispatch(appOperations.finish()),
    onDismissUploadError: () => dispatch(appOperations.dismissUploadError()),
    onForceDeleteData: () => dispatch(appOperations.forceDeleteData())
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(WorkArea)
);
