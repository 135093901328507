import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import cx from 'classnames';
import { padStart } from 'lodash';
import { matchPath, Link } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import {
  BottomNavigation,
  BottomNavigationAction,
  withStyles
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Drawer from '@material-ui/core/Drawer';
import ButtonBase from '@material-ui/core/ButtonBase';

import history from 'config/history';
import { selectors as appSelectors } from 'redux/modules/app';
import BackgroundLoader from 'modules/app/data-loader/background-loader';
import Menu from './menu';
import styles from './styles.module.css';

const MenuButton = withStyles(theme => ({
  root: {
    marginLeft: -12,
    marginRight: 20,
    color: theme.palette.getContrastText(theme.palette.primary[500])
  }
}))(IconButton);

const HomeButton = styled(ButtonBase)`
  padding: 4px 8px;
`;

const HomeLink = styled(Link)`
  color: inherit;
  text-decoration: none;
`;

const HeaderButton = withStyles(theme => ({
  root: {
    color: theme.palette.getContrastText(theme.palette.primary[500])
  }
}))(Button);

export class Layout extends Component {
  static propTypes = {
    inventory: PropTypes.object.isRequired,
    children: PropTypes.node,
    bottomTabs: PropTypes.array,
    onFinish: PropTypes.func
  };

  state = {
    menuOpen: false
  };

  handleButtonFocus = event => {
    event.target.blur();
  };

  componentDidMount() {
    this.menuButtonRef.addEventListener('focus', this.handleButtonFocus, true);
    this.completeButtonRef.addEventListener(
      'focus',
      this.handleButtonFocus,
      true
    );
  }

  componentWillUnmount() {
    this.menuButtonRef.removeEventListener(
      'focus',
      this.handleButtonFocus,
      true
    );
    this.completeButtonRef.removeEventListener(
      'focus',
      this.handleButtonFocus,
      true
    );
  }

  toggleDrawer = open => {
    this.setState({ menuOpen: open });
  };

  render() {
    const { children, inventory, bottomTabs, onFinish = null } = this.props;
    const { menuOpen } = this.state;

    let index = -1;

    for (let i = 0; i < bottomTabs.length; ++i) {
      const tab = bottomTabs[i];

      if (
        matchPath(history.location.pathname, { path: tab.route, exact: true })
      ) {
        index = i;
      }
    }

    return (
      <div>
        <AppBar className={cx(styles.mui, styles.appBar)}>
          <Toolbar>
            <MenuButton
              onClick={() => this.toggleDrawer(true)}
              buttonRef={ref => (this.menuButtonRef = ref)}
            >
              <MenuIcon />
            </MenuButton>
            <div className={styles.appBarTitle}>
              <HomeButton component={HomeLink} to="/">
                <div>
                  <Typography variant="body1" color="inherit">
                    {inventory.name}
                  </Typography>
                  <Typography variant="body2" color="inherit">
                    {`${padStart(inventory.store.id, 3, '0')} ${
                      inventory.store.name
                    }`}
                  </Typography>
                </div>
              </HomeButton>
            </div>
            <HeaderButton
              onClick={onFinish}
              ref={ref => (this.completeButtonRef = ref)}
            >
              Inventur abschließen
            </HeaderButton>
          </Toolbar>
        </AppBar>
        <div className={styles.content}>
          <BackgroundLoader />
          {children}
        </div>
        <Paper elevation={8} square className={styles.bottomNavigation}>
          <BottomNavigation value={index} onChange={() => {}} showLabels>
            {bottomTabs &&
              bottomTabs.map((tab, index) => (
                <BottomNavigationAction
                  key={index}
                  label={tab.label}
                  icon={tab.icon}
                  onClick={() => {
                    history.push(tab.route);
                  }}
                  className={styles.bottomTab}
                />
              ))}
          </BottomNavigation>
        </Paper>
        <Drawer open={menuOpen} onClose={() => this.toggleDrawer(false)}>
          <Menu onClose={() => this.toggleDrawer(false)} />
        </Drawer>
      </div>
    );
  }
}

const mapState = state => ({
  inventory: appSelectors.getActiveInventory(state)
});

export default connect(mapState)(Layout);
