import * as types from './types';

export const init = () => {
  return {
    type: types.INIT
  };
};

export const dbUpdate = () => {
  return {
    type: types.DB_UPDATE
  };
};

export const swUpdateAvailable = () => ({
  type: types.SW_UPDATE_AVAILABLE
});

export const start = inventory => {
  return {
    type: types.START,
    payload: {
      inventory: inventory
    }
  };
};

export const finish = () => {
  return {
    type: types.FINISH
  };
};

export const clearProducts = () => ({
  type: types.CLEAR_PRODUCTS
});

export const fetchProducts = () => {
  return {
    type: types.FETCH_PRODUCTS
  };
};

export const receiveProducts = (cursor, lastCursor = null) => {
  return {
    type: types.RECEIVE_PRODUCTS,
    payload: {
      cursor: cursor,
      lastCursor: lastCursor
    }
  };
};

export const updateProductsFinished = () => ({
  type: types.UPDATE_PRODUCTS_FINISHED
});

export const fetchProductsOffline = () => ({
  type: types.FETCH_PRODUCTS_OFFLINE
});

export const fetchProductsError = message => ({
  type: types.FETCH_PRODUCTS_ERROR,
  payload: { message }
});

export const fetchInventories = () => {
  return {
    type: types.FETCH_INVENTORIES
  };
};

export const receiveInventories = inventories => {
  return {
    type: types.RECEIVE_INVENTORIES,
    payload: {
      inventories: inventories
    }
  };
};

export const fetchInventoriesError = () => {
  return {
    type: types.FETCH_INVENTORIES_ERROR
  };
};

export const clearInventories = () => {
  return {
    type: types.CLEAR_INVENTORIES
  };
};

export const fetchUpload = () => {
  return {
    type: types.FETCH_UPLOAD
  };
};

export const receiveUpload = () => {
  return {
    type: types.RECEIVE_UPLOAD
  };
};

export const errorUpload = (message, confirmable = false) => {
  return {
    type: types.ERROR_UPLOAD,
    payload: { message, confirmable }
  };
};

export const dismissUploadError = () => ({
  type: types.DISMISS_UPLOAD_ERROR
});

export const displayAlert = (title, message) => {
  return {
    type: types.DISPLAY_ALERT,
    payload: {
      title: title,
      message: message
    }
  };
};

export const confirmAlert = () => {
  return {
    type: types.CONFIRM_ALERT
  };
};

export default {
  init,
  dbUpdate,
  swUpdateAvailable,
  start,
  finish,
  clearProducts,
  fetchProducts,
  receiveProducts,
  updateProductsFinished,
  fetchProductsOffline,
  fetchProductsError,
  fetchInventories,
  receiveInventories,
  fetchInventoriesError,
  clearInventories,
  fetchUpload,
  receiveUpload,
  errorUpload,
  dismissUploadError,
  displayAlert,
  confirmAlert
};
