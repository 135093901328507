import db from '../db';

class ScannedItems {
  builder() {
    return db.scanned_items;
  }

  insert(item) {
    return this.builder().put(item);
  }

  remove(id) {
    return this.builder().delete(id);
  }

  clear() {
    return this.builder().clear();
  }

  find(id) {
    return this.builder()
      .where('id')
      .equals(id)
      .first();
  }
}

const Repository = new ScannedItems();

export default Repository;
