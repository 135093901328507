import { PRODUCT_GROUPS } from '../../../config/constants';
import Grid from '@material-ui/core/Grid';
import React from 'react';

export const config = {
  columns: [
    {
      label: 'Warengruppe',
      property: 'group',
      cellProps: {
        width: '18%',
        style: {
          paddingRight: '8px',
          paddingLeft: '8px',
          wordBreak: 'break-word'
        }
      },
      render: value => value && PRODUCT_GROUPS[value]
    },
    {
      label: 'Marke/Depot',
      property: 'name',
      cellProps: {
        width: '25%',
        style: {
          paddingRight: '8px',
          paddingLeft: '8px',
          wordBreak: 'break-word'
        }
      }
    },
    {
      label: 'Stk.',
      property: 'amount',
      cellProps: {
        align: 'right',
        width: '12%',
        style: {
          paddingRight: '8px',
          paddingLeft: '8px',
          wordBreak: 'break-word'
        }
      },
      render: value => value && value.toLocaleString()
    },
    {
      label: 'VK',
      property: 'price',
      cellProps: {
        align: 'right',
        width: '15%',
        style: {
          paddingRight: '8px',
          paddingLeft: '8px',
          wordBreak: 'break-word'
        }
      },
      render: value =>
        value &&
        value.toLocaleString(undefined, { minimumFractionDigits: 2 }) + ' €'
    },
    {
      label: 'Wertminderung',
      property: 'reduction',
      cellProps: {
        width: 'calc(30% - 56px)',
        style: {
          paddingRight: '8px',
          paddingLeft: '8px',
          wordBreak: 'break-word'
        }
      },
      render: reduction => {
        if (!reduction) return null;

        return (
          <Grid container spacing={0}>
            <Grid item xs={3}>
              {reduction.old ? 'A' : null}
            </Grid>
            <Grid item xs={3}>
              {reduction.damaged ? 'B' : null}
            </Grid>
            <Grid item xs={3}>
              {reduction.fashion ? 'M' : null}
            </Grid>
            <Grid item xs={3}>
              {reduction.unsaleable ? 'UV' : null}
            </Grid>
          </Grid>
        );
      }
    },
    {
      label: '',
      property: 'id',
      cellProps: {
        padding: 'checkbox'
      }
    }
  ]
};
