import db from '../db';

class Products {
  builder() {
    return db.products;
  }

  insertBulk(products) {
    return this.builder().bulkPut(products);
  }

  clear() {
    return this.builder().clear();
  }

  find(id) {
    return this.builder()
      .where('id')
      .equals(id)
      .first();
  }

  findByEan(ean) {
    return this.builder()
      .where('ean')
      .equals(ean)
      .first();
  }

  findByParma(parma_number) {
    return this.builder()
      .where('parma_number')
      .equals(parma_number)
      .first();
  }
}

const productRepository = new Products();

export default productRepository;
