import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core';

export default class Alert extends Component {
  static propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    title: PropTypes.string,
    message: PropTypes.string,
    actions: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        onClick: PropTypes.func.isRequired
      })
    )
  };

  render() {
    const { open, onClose, title, message } = this.props;
    let { actions } = this.props;

    if (!actions || !actions.length) {
      actions = [
        {
          label: 'Ok',
          onClick: onClose
        }
      ];
    }

    return (
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          {actions.map((action, index) => (
            <Button key={index} onClick={action.onClick} color="primary">
              {action.label}
            </Button>
          ))}
        </DialogActions>
      </Dialog>
    );
  }
}
